import React, { useEffect, useState, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Typography,
    Switch,
    FormControlLabel,
    Alert,
    Checkbox
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Logo from '../wayfaster-logo.svg'
//@TODO make sure webcam isn't being used if it's not required - chrome says its still active when is disabled


interface WebcamPreviewProps {
    isMobile: boolean;
    isWebcamRequired: boolean;
    open: boolean;
    timer: number;
    jobTitle: string;
    onClose: () => void;
    onDevicesSelected: (videoDeviceId: string, audioDeviceId: string) => void;
    showDisclaimers: boolean;
}

const WebcamPreviewModal: React.FC<WebcamPreviewProps> = ({
    isMobile,
    isWebcamRequired,
    open,
    timer,
    jobTitle,
    onClose,
    onDevicesSelected,
    showDisclaimers,
}) => {
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [useWebcam, setUseWebcam] = useState<boolean>(true);
    const [selectedVideo, setSelectedVideo] = useState<string>('');
    const [selectedAudio, setSelectedAudio] = useState<string>('');
    const [useHeadphones, setUseHeadphones] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const analyzerRef = useRef<AnalyserNode | null>(null);
    const animationFrameRef = useRef<number>();
    const checkIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const [webcamActive, setWebcamActive] = useState<boolean>(false);
    const [micActive, setMicActive] = useState<boolean>(false);
    const [inputsEnabled, setInputsEnabled] = useState<boolean>(false);
    const [webcamHasVideo, setWebcamHasVideo] = useState<boolean>(false);
    const [isCheckingVideo, setIsCheckingVideo] = useState<boolean>(false);
    const [videoCheckError, setVideoCheckError] = useState<string>('');

    useEffect(() => {
        if (open && useWebcam) {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    const videos = devices.filter(device => device.kind === 'videoinput');
                    const audios = devices.filter(device => device.kind === 'audioinput');
                    setVideoDevices(videos);
                    setAudioDevices(audios);

                    if (videos.length) setSelectedVideo(videos[0].deviceId);
                    if (audios.length) setSelectedAudio(audios[0].deviceId);
                })
                .catch(err => console.error('Error enumerating devices:', err));
        }
    }, [open, useWebcam, inputsEnabled]);

    // Advanced detection for covered/blocked webcams, including detection of thumbs and hands
    const checkIfVideoIsBlack = () => {
        if (!videoRef.current || !videoRef.current.videoWidth || !videoRef.current.videoHeight) {
            return false;
        }
        
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        
        if (!context) return false;
        
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        
        // Draw the current video frame to the canvas
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        
        // Get the pixel data from the canvas
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const pixels = imageData.data;
        
        // Create much denser sampling grid for more thorough analysis
        const gridSize = 5; // Increase from 3 to 5 for more sample points
        const regions = [];
        
        // Create a dense grid of sampling points
        for (let i = 0; i <= gridSize; i++) {
            for (let j = 0; j <= gridSize; j++) {
                regions.push({
                    x: Math.floor(canvas.width * (i / gridSize)),
                    y: Math.floor(canvas.height * (j / gridSize))
                });
            }
        }
        
        // Add center region points for better coverage
        regions.push({ x: Math.floor(canvas.width * 0.5), y: Math.floor(canvas.height * 0.5) });
        
        // Statistics to track
        let brightPoints = 0;
        let totalBrightness = 0;
        let redDominantPoints = 0;
        let uniformColorPoints = 0;
        let totalHue = 0;
        let totalSaturation = 0;
        let hasSufficientContrast = false;
        let hasNormalColorDistribution = false;
        let maxBrightness = 0;
        let minBrightness = 255;
        let colorHistogram = {
            red: 0,
            green: 0,
            blue: 0
        };
        
        // RGB to HSV conversion helper for color analysis
        const rgbToHsv = (r: number, g: number, b: number) => {
            r /= 255;
            g /= 255;
            b /= 255;
        
            const max = Math.max(r, g, b);
            const min = Math.min(r, g, b);
            let h = 0, s = 0, v = max;
        
            const d = max - min;
            s = max === 0 ? 0 : d / max;
        
            if (max === min) {
                h = 0; // achromatic
            } else {
                switch (max) {
                    case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                    case g: h = (b - r) / d + 2; break;
                    case b: h = (r - g) / d + 4; break;
                }
                h /= 6;
            }
        
            return { h: h * 360, s: s * 100, v: v * 100 };
        };
        
        // Check for suspicious patterns like solid blocks of color (especially in skin/thumb tones)
        const colorVariance = {
            r: 0,
            g: 0, 
            b: 0
        };
        const rgbValues: number[][] = [];
        
        // First pass to gather pixel data
        for (const region of regions) {
            const index = (region.y * canvas.width + region.x) * 4;
            if (index >= pixels.length) continue;
            
            const r = pixels[index];
            const g = pixels[index + 1];
            const b = pixels[index + 2];
            
            // Store RGB values for variance calculation
            rgbValues.push([r, g, b]);
            
            // Track color dominance in histogram
            if (r > g + 30 && r > b + 30) colorHistogram.red++;
            else if (g > r + 30 && g > b + 30) colorHistogram.green++;
            else if (b > r + 30 && b > g + 30) colorHistogram.blue++;
            
            // Detect skin tones (simplified detection - reddish/orange tones)
            const hsv = rgbToHsv(r, g, b);
            totalHue += hsv.h;
            totalSaturation += hsv.s;
            
            // Detect reddish skin tones (common for thumbs covering camera)
            // Skin tone hue is typically between 0-50 (red to orange/yellow)
            if (hsv.h >= 0 && hsv.h <= 50 && r > g && r > b) {
                redDominantPoints++;
            }
            
            // Calculate brightness (simple average method)
            const brightness = (r + g + b) / 3;
            totalBrightness += brightness;
            
            // Track brightness stats
            if (brightness > maxBrightness) maxBrightness = brightness;
            if (brightness < minBrightness) minBrightness = brightness;
            
            // Count points that exceed minimum brightness
            if (brightness > 15) {
                brightPoints++;
            }
        }
        
        // Calculate color variance (low variance = likely covered with consistent color like a finger)
        if (rgbValues.length > 0) {
            const rValues = rgbValues.map(rgb => rgb[0]);
            const gValues = rgbValues.map(rgb => rgb[1]);
            const bValues = rgbValues.map(rgb => rgb[2]);
            
            // Calculate average
            const rAvg = rValues.reduce((sum, val) => sum + val, 0) / rValues.length;
            const gAvg = gValues.reduce((sum, val) => sum + val, 0) / gValues.length;
            const bAvg = bValues.reduce((sum, val) => sum + val, 0) / bValues.length;
            
            // Calculate variance
            colorVariance.r = rValues.reduce((sum, val) => sum + Math.pow(val - rAvg, 2), 0) / rValues.length;
            colorVariance.g = gValues.reduce((sum, val) => sum + Math.pow(val - gAvg, 2), 0) / gValues.length;
            colorVariance.b = bValues.reduce((sum, val) => sum + Math.pow(val - bAvg, 2), 0) / bValues.length;
        }
        
        // Analyze entire image through histogram
        const totalPixels = regions.length;
        const avgHue = totalHue / totalPixels;
        const avgSaturation = totalSaturation / totalPixels;
        
        // Calculate various metrics to detect covering
        const contrast = maxBrightness - minBrightness;
        hasSufficientContrast = contrast > 30; // Increased threshold
        
        // Calculate average brightness across all sampled points
        const averageBrightness = totalBrightness / regions.length;
        
        // Calculate average color variance - low variance means uniform color (like a thumb)
        const avgColorVariance = (colorVariance.r + colorVariance.g + colorVariance.b) / 3;
        const hasLowColorVariance = avgColorVariance < 500; // Threshold for detecting uniform color blocks
        
        // Detect if the camera is likely covered by a finger/thumb (reddish color dominance)
        const isLikelyCoveredByFinger = redDominantPoints > regions.length * 0.6 && hasLowColorVariance;
        
        // Check if colors are distributed normally (as expected in a real scene)
        const maxColorCount = Math.max(colorHistogram.red, colorHistogram.green, colorHistogram.blue);
        const totalColoredPoints = colorHistogram.red + colorHistogram.green + colorHistogram.blue;
        hasNormalColorDistribution = maxColorCount < totalColoredPoints * 0.7; // No single color should dominate too much
        
        // Detect suspicious patterns
        const hasSuspiciousPattern = isLikelyCoveredByFinger || (hasLowColorVariance && !hasNormalColorDistribution);
        
        // Evaluate multiple criteria to determine if camera is usable
        const hasAdequateImage = (brightPoints >= Math.ceil(regions.length * 0.3)) && // At least 30% of points should be bright enough
                                hasSufficientContrast && // Should have adequate contrast
                                !hasSuspiciousPattern && // Should not show suspicious patterns
                                (averageBrightness > 15); // Minimum average brightness
        
        // Detailed logging about the detection metrics
        // console.log(`[WebcamPreview] Detection stats: 
        // - Bright points: ${brightPoints}/${regions.length} (need ${Math.ceil(regions.length * 0.3)})
        // - Contrast: ${contrast.toFixed(1)} (need >30)
        // - Reddish points: ${redDominantPoints}/${regions.length}
        // - Has suspicious pattern: ${hasSuspiciousPattern}
        // - Average brightness: ${averageBrightness.toFixed(1)} (need >15)
        // - Color variance: ${(colorVariance.r + colorVariance.g + colorVariance.b).toFixed(1)}
        // - FINAL RESULT: ${hasAdequateImage ? 'PASS ✅' : 'FAIL ❌'}`);
        
        return hasAdequateImage;
    };
    
    useEffect(() => {
        if (selectedVideo && videoRef.current && useWebcam) {
            setIsCheckingVideo(true);
            setWebcamHasVideo(false);
            setVideoCheckError('');
            
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }

            navigator.mediaDevices.getUserMedia({
                video: { deviceId: selectedVideo },
                audio: false
            })
                .then(stream => {
                    streamRef.current = stream;
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                        
                        // Wait a moment for the video to load before checking
                        videoRef.current.onloadeddata = () => {
                            // Check if the video is black after a short delay to ensure proper loading
                            setTimeout(() => {
                                setWebcamHasVideo(true);
                                setIsCheckingVideo(false);
                            }, 1000);
                        };
                        
                        // Set up continuous check for webcam status
                        // if (checkIntervalRef.current) {
                        //     clearInterval(checkIntervalRef.current);
                        // }
                        
                        // checkIntervalRef.current = setInterval(() => {
                        //     console.log('[WebcamPreview] Checking webcam status');
                        //     if (videoRef.current && videoRef.current.readyState === 4) {
                        //         const isBlack = checkIfVideoIsBlack();
                        //         setWebcamHasVideo(!isBlack);
                        //         if (!isBlack) {
                        //             setVideoCheckError('⚠️ CAMERA COVERED OR BLOCKED ⚠️\nWe\'ve detected that your webcam may be covered by a finger/thumb, physically blocked, or showing a solid color. Please ensure your camera lens is completely clear and uncovered. This interview requires a working, unobstructed webcam.');
                        //         } else {
                        //             setVideoCheckError('');
                        //         }
                        //     }
                        // }, 500); 
                    }
                    setWebcamActive(true);
                    if(!inputsEnabled) {
                        setInputsEnabled(true);
                    }
                })
                .catch(err => {
                    console.error('Error accessing media devices:', err);
                    setWebcamActive(false);
                    setWebcamHasVideo(false);
                    setIsCheckingVideo(false);
                    if(!inputsEnabled) {
                        setInputsEnabled(false);
                    }
                });
        }

        return () => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
            
            // Clean up interval
            if (checkIntervalRef.current) {
                clearInterval(checkIntervalRef.current);
                checkIntervalRef.current = null;
            }
        };
    }, [selectedVideo, useWebcam, inputsEnabled]);

    useEffect(() => {
        if (selectedAudio) {
            navigator.mediaDevices.getUserMedia({
                audio: { deviceId: selectedAudio }
            })
                .then(stream => {
                    const audioContext = new AudioContext();
                    const source = audioContext.createMediaStreamSource(stream);
                    const analyzer = audioContext.createAnalyser();
                    analyzer.fftSize = 512;
                    analyzer.smoothingTimeConstant = 0.8;
                    source.connect(analyzer);
                    analyzerRef.current = analyzer;
                    setMicActive(true);

                    const drawAudioLevel = () => {
                        if (!canvasRef.current || !analyzer) return;

                        const canvas = canvasRef.current;
                        const ctx = canvas.getContext('2d');
                        if (!ctx) return;

                        const dataArray = new Uint8Array(analyzer.frequencyBinCount);
                        analyzer.getByteFrequencyData(dataArray);

                        // Clear canvas with a slight fade effect
                        // ctx.fillStyle = 'rgba(245, 245, 245, 0.2)';
                        // ctx.fillRect(0, 0, canvas.width, canvas.height);

                        ctx.clearRect(0, 0, canvas.width, canvas.height);

                        // Set up visualization parameters
                        const centerY = canvas.height / 2;
                        const barWidth = 4;
                        const barSpacing = 2;
                        const maxBarHeight = canvas.height * 0.6;
                        const bars = Math.min(dataArray.length, Math.floor(canvas.width / (barWidth + barSpacing)));
                        const startX = (canvas.width - (bars * (barWidth + barSpacing))) / 2;

                        // Draw each bar
                        for (let i = 0; i < bars; i++) {
                            const value = dataArray[i];
                            const barHeight = (value / 255) * maxBarHeight;
                            const x = startX + i * (barWidth + barSpacing);

                            // Create gradient for each bar
                            const gradient = ctx.createLinearGradient(0, centerY - barHeight / 2, 0, centerY + barHeight / 2);
                            //gradient.addColorStop(0, '#F4E132');
                            gradient.addColorStop(0, '#FFFFFF');
                            gradient.addColorStop(1, '#FFFFFF');

                            // Draw bar with rounded corners
                            ctx.beginPath();
                            ctx.roundRect(
                                x,
                                centerY - barHeight / 2,
                                barWidth,
                                barHeight,
                                2
                            );
                            ctx.fillStyle = gradient;
                            ctx.fill();
                        }

                        animationFrameRef.current = requestAnimationFrame(drawAudioLevel);
                    };

                    drawAudioLevel();
                })
                .catch(err => {
                    console.error('Error accessing microphone:', err);
                    setMicActive(false);
                });
        }

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [selectedAudio, micActive]);

    useEffect(() => {
        if (!useWebcam && streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }
    }, [useWebcam]);

    const [confirmAttempted, setConfirmAttempted] = useState<boolean>(false);
    
    const handleConfirm = () => {
        console.log('[WebcamPreview] Confirm button clicked - running webcam verification process');
        // First mark that we've attempted to confirm - this will show errors more aggressively
        setConfirmAttempted(true);
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }
                
        onDevicesSelected(selectedVideo, selectedAudio);

        
        // Always perform multiple webcam checks before proceeding to ensure camera isn't covered
        // if (isWebcamRequired && videoRef.current) {
        //     // Run a series of checks to make absolutely sure the camera isn't covered
        //     // First immediate check - captures current state
        //     const hasVideoFirst = checkIfVideoIsBlack();
        //     console.log(`[WebcamPreview] First verification check: ${hasVideoFirst ? 'PASS' : 'FAIL'}`);
            
        //     if (!webcamActive || !hasVideoFirst) {
        //         // Immediately show error if camera is covered on first check
        //         console.log('[WebcamPreview] First check failed - webcam appears to be covered or inactive');
        //         setWebcamHasVideo(false);
        //         setVideoCheckError('⚠️ CAMERA COVERED OR BLOCKED ⚠️\nWe\'ve detected that your webcam may be covered by a finger/thumb, physically blocked, or showing a solid color. Please ensure your camera lens is completely clear and uncovered. This interview requires a working, unobstructed webcam.');
                
        //         // Add a vibrant red outline to the video display to highlight the issue
        //         if (videoRef.current) {
        //             console.log('[WebcamPreview] Adding red highlight to webcam feed');
        //             videoRef.current.style.border = '3px solid #ff0000';
        //             videoRef.current.style.boxShadow = '0 0 10px #ff0000';
                    
        //             // Remove the highlight after 2 seconds
        //             setTimeout(() => {
        //                 if (videoRef.current) {
        //                     videoRef.current.style.border = '';
        //                     videoRef.current.style.boxShadow = '';
        //                 }
        //             }, 2000);
        //         }
                
        //         return;
        //     }
            
        //     // Second check after small delay - in case they quickly covered the camera
        //     console.log('[WebcamPreview] First check passed, scheduling second check in 200ms');
        //     setTimeout(() => {
        //         const hasVideoSecond = checkIfVideoIsBlack();
        //         console.log(`[WebcamPreview] Second verification check: ${hasVideoSecond ? 'PASS' : 'FAIL'}`);
                
        //         if (!hasVideoSecond) {
        //             console.log('[WebcamPreview] Second check failed - webcam appears to be covered');
        //             setWebcamHasVideo(false);
        //             setVideoCheckError('⚠️ CAMERA COVERED OR BLOCKED ⚠️\nWe\'ve detected that your webcam may be covered by a finger/thumb, physically blocked, or showing a solid color. Please ensure your camera lens is completely clear and uncovered. This interview requires a working, unobstructed webcam.');
        //             return;
        //         }
                
        //         // Third and final check after another small delay for triple verification
        //         console.log('[WebcamPreview] Second check passed, scheduling final check in 200ms');
        //         setTimeout(() => {
        //             const hasVideoThird = checkIfVideoIsBlack();
        //             console.log(`[WebcamPreview] Third verification check: ${hasVideoThird ? 'PASS' : 'FAIL'}`);
                    
        //             if (!hasVideoThird) {
        //                 console.log('[WebcamPreview] Third check failed - webcam appears to be covered');
        //                 setWebcamHasVideo(false);
        //                 setVideoCheckError('⚠️ CAMERA COVERED OR BLOCKED ⚠️\nWe\'ve detected that your webcam may be covered by a finger/thumb, physically blocked, or showing a solid color. Please ensure your camera lens is completely clear and uncovered. This interview requires a working, unobstructed webcam.');
        //                 return;
        //             }
                    
        //             // If we've passed all three checks, proceed with confirming
        //             console.log('[WebcamPreview] All verification checks passed - proceeding with confirmation');
        //             if (streamRef.current) {
        //                 streamRef.current.getTracks().forEach(track => track.stop());
        //                 streamRef.current = null;
        //             }
        //             onDevicesSelected(useWebcam ? selectedVideo : '', selectedAudio);
        //             onClose();
                    
        //         }, 200); // Third check after another 200ms
        //     }, 200); // Second check after 200ms
        // } else {
        //     // No webcam required, just proceed
        //     if (streamRef.current) {
        //         streamRef.current.getTracks().forEach(track => track.stop());
        //         streamRef.current = null;
        //     }
        //     onDevicesSelected(useWebcam ? selectedVideo : '', selectedAudio);
        //     onClose();
        // }
    };

    const handleEnableCameraAndMic = () => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then(function (stream) {
                setInputsEnabled(true);
            })
            .catch(function (err) {
                console.log('Inputs permission denied');
            });        
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            maxWidth={false}
            fullWidth
            fullScreen
            PaperProps={{
                sx: {
                    borderRadius: 0,
                    overflow: 'auto',
                    height: '100vh',
                    maxHeight: '100vh'
                }
            }}
        >            
          <div style={{
            padding: 16,
            margin: '16px 24px',
            border: '1px solid var(--color-border-gray)',
            borderRadius: 16,
          }} className={`d-flex ${isMobile ? 'flex-col' : 'flex-row'}`}>
              <div className="font-inter d-flex my-auto" style={{ marginRight: 'auto', marginLeft: isMobile ? 'auto' : '', fontSize: 18, color: 'var(--color-text-on-light)'}}>
                <a href="https://wayfaster.com" rel="noopener noreferrer" target="_blank"><img src={Logo} alt="Wayfaster" style={{ height: '28px' }} /></a>
              </div>
              <div className="d-flex" style={{ marginLeft: 'auto', marginRight: isMobile ? 'auto' : '' }}>
                <p className="font-inter" style={{ margin: 'auto', fontSize: 14, color: 'var(--color-text-on-light)', fontWeight: 500}}>
                  {jobTitle}
                </p>
                <p className="font-inter" style={{ margin: 'auto 16px', fontSize: 18, color: '#E4E4E7', fontWeight: 500}}>
                  |
                </p>
                <div className="d-flex flex-row timer-container" style={{ marginLeft: 'auto' }}>
                  <AccessTimeIcon style={{ fontSize: 16, margin: 'auto', marginRight: 4 }}/>
                  <p className="font-inter" style={{ margin: 'auto', fontSize: '14px', fontWeight: 500}}>
                    {secondsToHumanReadableTime(timer)}
                  </p>
                </div>
              </div>
          </div>
            <Box sx={{
                display: 'flex',
                gap: 5,
                flexDirection: { xs: 'column', md: 'row' },
                minHeight: { xs: 'min-content', md: 'auto' },
                paddingBottom: { xs: '24px', md: '0' },
                border: '1px solid var(--color-border-gray)',
                borderRadius: '16px',
                padding: '16px',
                margin: '16px 24px',
            }}>
                <DialogContent sx={{ padding: '24px' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                        maxWidth: '100%',
                        margin: '0',
                        height: '80%',
                        //paddingTop: '5%',
                        paddingLeft: isMobile ? '0px' : '32px'
                    }}>
                        {useWebcam ? (
                            <>
                                {/* Title Row */}
                                <Typography
                                    className="font-inter"
                                    sx={{
                                        color: 'black',
                                        fontSize: { xs: '14px', md: '16px' },
                                        fontWeight: 600,
                                    }}
                                >
                                    Make sure the microphone and camera are working properly
                                </Typography>

                                {/* Video and Audio Preview Container */}
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}>
                                    {/* Video Container with Audio Overlay */}
                                    <Box
                                        sx={{
                                        position: 'relative',  // This is needed just for overlay positioning
                                        width: '100%',
                                        height: { xs: '300px', md: '400px', lg: '500px', xl: '650px' },
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                    }}>
                                        <video
                                            ref={videoRef}
                                            autoPlay
                                            playsInline
                                            className="video-feed-mirrored"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                backgroundColor: '#f5f5f5',
                                                border: confirmAttempted && !webcamHasVideo ? '3px solid #ff0000' : 'none',
                                                boxShadow: confirmAttempted && !webcamHasVideo ? '0 0 10px rgba(255, 0, 0, 0.7)' : 'none',
                                                transition: 'border 0.3s, box-shadow 0.3s'
                                            }}
                                        />
                                        {/* Audio Visualization Overlay */}
                                        <Box sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: isMobile ? '50%' : '20px',
                                            transform: isMobile ? 'translateX(-50%)' : 'translateX(0%)',
                                            width: '250px',
                                            height: '150px',
                                            borderRadius: '8px',
                                            overflow: 'hidden',
                                            //backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                        }}>
                                            <canvas
                                                ref={canvasRef}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            //width={300}
                                            //height={150}
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                {/* Device Controls Row */}
                                {inputsEnabled && (
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexDirection: { xs: 'column', md: 'row' }
                                    }}>
                                        <FormControl fullWidth>
                                            <InputLabel sx={{
                                                color: '#000',
                                                '&.Mui-focused': { color: '#000' }
                                            }}>Camera</InputLabel>
                                            <Select
                                                value={selectedVideo}
                                                onChange={(e) => setSelectedVideo(e.target.value)}
                                                label="Camera"
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#E9E9E9' },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#000' },
                                                    '& .Mui-selected': { color: '#666666' }
                                                }}
                                            >
                                                {videoDevices.map(device => (
                                                    <MenuItem key={device.deviceId} value={device.deviceId}>
                                                        {device.label || `Camera ${device.deviceId.slice(0, 5)}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <InputLabel sx={{
                                                color: '#000',
                                                '&.Mui-focused': { color: '#000' }
                                            }}>Microphone</InputLabel>
                                            <Select
                                                value={selectedAudio}
                                                onChange={(e) => setSelectedAudio(e.target.value)}
                                                label="Microphone"
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#E9E9E9' },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#000' },
                                                    '& .Mui-selected': { color: '#666666' }
                                                }}
                                            >
                                                {audioDevices.map(device => (
                                                    <MenuItem key={device.deviceId} value={device.deviceId}>
                                                        {device.label || `Microphone ${device.deviceId.slice(0, 5)}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )}
                            </>
                        ) : (
                            <Box sx={{
                                width: '100%',
                                height: '300px',
                                marginBottom: '16px'
                            }}>
                                <canvas
                                    ref={canvasRef}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '8px',
                                    }}
                                    width={300}
                                    height={300}
                                />
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <Box sx={{
                    display: 'flex',
                    width: isMobile ? '90%' : '40%',
                    alignContent: "center",
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: { xs: '0 24px 24px', md: '0' },
                }}>
                    <DialogTitle sx={{
                        fontFamily: 'PPNeueMontreal',
                        fontWeight: 600,
                        fontSize: { xs: '16px', md: '20px' },
                        padding: { xs: '0 0 0', md: '0' },
                        width: '100%',
                        textAlign: 'center',
                    }}>
                        Ready for the interview?
                    </DialogTitle>

                    <DialogTitle sx={{
                        fontFamily: 'PPNeueMontreal',
                        fontWeight: 400,
                        width: isMobile ? '100%' : '70%',
                        fontSize: { xs: '12px', md: '16px' },
                        color: 'gray',
                        paddingBottom: '12px',
                        textAlign: 'center',
                    }}>
                        Once you have chosen the right camera and microphone settings, you can join the meeting
                        <br />

                        {showDisclaimers && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
                                <Typography sx={{ fontWeight: 600, color: 'var(--color-text-on-light)' }}>
                                    Please check the below before continuing:
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    sx={{
                                        '&.Mui-checked': {
                                            color: 'var(--color-primary)',
                                        },
                                    }}
                                    id="headphones-checkbox"
                                    onChange={(e) => {
                                        setUseHeadphones(e.target.checked);
                                    }}
                                />
                                <label htmlFor="headphones-checkbox" style={{ fontSize: '13px', fontWeight: 600, color: 'var(--color-text-on-light)' }}>
                                    Yes, I am using headphones for the smoothest audio experience
                                </label>
                                </Box>
                            </Box>
                        )}
                        <br />
                        <br />
                    </DialogTitle>

                    <DialogActions sx={{
                        padding: '0px',
                        width: '100%',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {/* Error message for black/covered webcam */}
                        {videoCheckError && inputsEnabled && (
                            <>
                                <DialogTitle sx={{
                                    fontFamily: 'PPNeueMontreal',
                                    fontWeight: 600,
                                    width: isMobile ? '90%' : '80%',
                                    maxWidth: '500px',
                                    fontSize: { xs: '12px', md: '16px' },
                                    color: '#d32f2f',
                                    paddingBottom: '24px',
                                    textAlign: 'center',
                                    mx: 'auto',
                                }}>
                                    A working webcam with video is required to continue with the interview. If you cover your camera at any point in the interview, you may be disqualified.
                                    <Alert 
                                        severity="error"
                                        variant="filled"
                                        sx={{ 
                                            mb: 2,
                                            mt: 2,
                                            fontFamily: 'PPNeueMontreal',
                                            fontSize: '14px',
                                            textAlign: 'center',
                                            width: isMobile ? '90%' : '80%',
                                            maxWidth: '500px',
                                            mx: 'auto',
                                            '& .MuiAlert-message': { 
                                                width: '100%',
                                                whiteSpace: 'pre-line',
                                                textAlign: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }
                                        }}
                                    >
                                        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                                            ⚠️ CAMERA ISSUE DETECTED ⚠️
                                        </div>
                                        <div>
                                            We've detected that your webcam may be covered by a finger/thumb, 
                                            physically blocked, or showing a solid color.
                                        </div>
                                        <div style={{ marginTop: '8px' }}>
                                            Please ensure your camera lens is completely clear and uncovered.
                                        </div>
                                    </Alert>                                    
                                </DialogTitle>                            
                            </>
                        )}
                        
                        {/* Loading indicator while checking video */}
                        {isCheckingVideo && inputsEnabled && (
                            <Typography 
                                sx={{ 
                                    mb: 2,
                                    fontFamily: 'PPNeueMontreal',
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}
                            >
                                Checking camera feed...
                            </Typography>
                        )}
                        
                        {!inputsEnabled && (
                            <Button
                                onClick={handleEnableCameraAndMic}
                                variant="contained"
                                fullWidth
                                sx={{
                                    maxWidth: isMobile ? '100%' : '270px',
                                    background: '#000',
                                    color: 'white', 
                                    borderRadius: '12px',
                                    fontFamily: 'PPNeueMontreal',
                                    paddingY: '10px',
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    '&:hover': {
                                        background: '#000',
                                    }
                                }}
                            >
                                Enable Camera and Mic
                            </Button>
                        )}
                        {inputsEnabled && (
                            <Button
                                onClick={handleConfirm} 
                                variant="contained"
                                disabled={!webcamActive || !micActive || isCheckingVideo || videoCheckError || (!useHeadphones && showDisclaimers)}
                                fullWidth
                                sx={{
                                    maxWidth: isMobile ? '100%' : '170px',
                                    background: '#000',
                                    color: 'white',
                                    borderRadius: '12px',
                                    fontFamily: 'PPNeueMontreal',
                                    paddingY: '10px',
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    '&:hover': {
                                        background: '#000',
                                    }
                                }}
                                autoFocus
                            >
                                Confirm & continue
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </Box>
        </Dialog>
    );
};

function secondsToHumanReadableTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

    return formattedTime;
  }

export default WebcamPreviewModal;
