import React, { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { Button, Container  } from '@mui/material';

import { ArrowForward } from "@mui/icons-material";
import { usePostHog } from 'posthog-js/react'

const Complete = () => {
    const posthog = usePostHog()
    const [searchParams] = useSearchParams()
    const direct = searchParams.get('direct') === 'true' // string literal on query param
    const end = searchParams.get('end')

    return (
        <div style={{ margin: 0, background: 'white', color: 'black', width: '100%', height: '100vh', display: 'flex' }}>
            <Container className="d-flex flex-column text-center my-auto" style={{ height: 'fit-content', paddingBottom: '56px' }}>
              <svg className="animated-check mx-auto" viewBox="0 0 24 24">
                <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
              </svg>
              <h2 className="font-circular">Great work! You've completed your Wayfaster interview.</h2>
              {end && end === 'midway' ? <p className="font-circular" style={{ marginTop: '24px' }}>
                The interview was ended before completion. 
                We can only evaluate you based on your partial input thus far. 
                This may impact your interview performance and evaluation for the given role.</p> : <></>}

              <div style={{ marginTop: '32px' }}>
                  {direct ? <Button className={`btn btn-wayfaster font-circular`} endIcon={<ArrowForward />}
                      style={{ marginLeft: 'auto', marginRight: 'auto' }}
                      onClick={() => {
                      window.location.href = 'https://candidates.wayfaster.com/apply/profile';
                  }}>
                  Go back to profile
              </Button> : <>
                  <h4 className="font-circular">You may now close this window.</h4>
              </>}
              </div>
            </Container>
        </div>
    )
}

export default Complete;