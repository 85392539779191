import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container, CircularProgress, FormControl, InputLabel, Select, MenuItem, Button, Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarToday, Close } from '@mui/icons-material';
import dayjs from 'dayjs';
import FormField from './components/FormField';
import FormSelect from './components/FormSelect';
import TooltipCustom from './components/TooltipCustom';
import { InfoOutlined, Clear } from '@mui/icons-material';
import Logo from './dynamic_session_logo.svg';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

var proxyEndpoint = process.env.REACT_APP_PROXY_ENDPOINT || 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var env = String(process.env.REACT_APP_ENV)
console.log('env', env)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
}


const Landing = () => {
  const { interviewId } = useParams();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    phoneCode: '+1'
  });
  const [additionalFormData, setAdditionalFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [companyLogo, setCompanyLogo] = useState(null);
  const [interviewTitle, setInterviewTitle] = useState('');
  const [additionalFields, setAdditionalFields] = useState([]);
  const [overview, setOverview] = useState('');
  const [tags, setTags] = useState([
    'Product',
    'Remote',
    'Full-time',
    '$150,000 - $208,000/year',
  ]);
  const [location, setLocation] = useState('');
  const [file, setFile] = useState(null);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFile(file);
    }
  };

  const clearFile = () => {
    setFile("");
  };

  useEffect(() => {
    // Fetch the interview details to get company logo if available
    const fetchInterviewDetails = async () => {
      try {
        // Using the existing endpoint to fetch interview details with compa ny logo
        console.log(`${proxyEndpoint}/interviews/public`)
        const response = await axios.post(`${proxyEndpoint}/interviews/public`, {
          interviewId: interviewId
        });

        console.log('response', response.data)
        
        if (response.data && response.data.company_logo) {
          setCompanyLogo(response.data.company_logo);
        }
        if (response.data && response.data.job_title) {
          setInterviewTitle(response.data.job_title);
        }

        if (response.data && response.data.additional_fields) {
          setAdditionalFields(response.data.additional_fields);
        }

        if (response.data && response.data.overview) {
          setOverview(response.data.overview);
        }
        
        if (response.data && response.data.tags) {
          setTags(response.data.tags);
        }

        if (response.data && response.data.location) {
          setLocation(response.data.location);
        }
      } catch (err) {
        console.error('Error fetching interview details:', err);
      }
    };

    fetchInterviewDetails();
  }, [interviewId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Create FormData object if we have a file
      let data;
      if (file) {
        const formDataObj = new FormData();
        formDataObj.append('interviewConfigId', interviewId);
        formDataObj.append('candidateFirstName', formData.firstName);
        formDataObj.append('candidateLastName', formData.lastName);
        formDataObj.append('candidateEmail', formData.email);
        formDataObj.append('candidatePhone', (formData.phoneCode?.length > 0 && formData.phone?.length > 0) ? formData.phoneCode + formData.phone : '');
        formDataObj.append('callType', 'web');
        formDataObj.append('dynamicSession', 'true');
        
        // Add all additional fields
        const additionalFieldsWithoutResume = {...additionalFormData};
        delete additionalFieldsWithoutResume.resume;  // Remove resume since we'll append it separately
        formDataObj.append('additionalFields', JSON.stringify(additionalFieldsWithoutResume));
        
        // Append the file last
        formDataObj.append('resume', file);

        data = formDataObj;
      } else {
        // No file, use regular JSON
        data = {
          interviewConfigId: interviewId,
          candidateFirstName: formData.firstName,
          candidateLastName: formData.lastName,
          candidateEmail: formData.email,
          candidatePhone: (formData.phoneCode?.length > 0 && formData.phone?.length > 0) ? formData.phoneCode + formData.phone : '',
          callType: 'web',
          dynamicSession: true,
          additionalFields: additionalFormData
        };
      }

      const response = await axios.post(`${proxyEndpoint}/sessions`, data, {
        headers: file ? {
          'Content-Type': 'multipart/form-data'
        } : {
          'Content-Type': 'application/json'
        }
      });

      // Redirect to the session page
      window.location.href = `/?session=${response.data}`;
    } catch (err) {
      setError('Failed to create session. Please try again.');
      console.error('Error creating session:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAdditionalChange = (e) => {
    setAdditionalFormData({
      ...additionalFormData,
      [e.target.name]: e.target.value
    });
  };

  const noAdditionalFields = useMemo(() => {
    return additionalFields.length === 0;
  }, [additionalFields]);

  const submitDisabled = useMemo(() => {
    const requiredFieldsPresent = 
      (!formData.email && !formData.phone) || 
      formData.firstName === '' || 
      formData.lastName === '' ||
      (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) ||
      (formData.phone && !/^[0-9]{6,15}$/.test(formData.phone));

    // Check if required additional fields are present when they exist in additionalFields
    const requiredAdditionalFieldsMissing = additionalFields.some(field => {
      if (field === 'compensation_expectations') {
        return !additionalFormData.compensation_expectations;
      }
      if (field === 'preferred_start_date') {
        return !additionalFormData.preferred_start_date;
      }
      if (field === 'need_for_sponsorship') {
        return !additionalFormData.need_for_sponsorship;
      }
      if (field === 'resume') {
        return !file;
      }
      return false;
    });

    return loading || requiredFieldsPresent || requiredAdditionalFieldsMissing;
  }, [loading, formData.email, formData.phone, formData.phoneCode, formData.firstName, formData.lastName, additionalFormData, additionalFields, file]);

  const JobHeader = React.memo(({...props}) => {
    return (
      <div className="d-flex flex-row" 
        style={{
          width: 'fit-content',
        }}
        {...props}
      >
        <div className="d-flex" style={{
          backgroundColor: companyLogo ? 'transparent' : 'var(--color-wayfaster)',
          backgroundImage: companyLogo ? `url(${companyLogo})` : `none`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '56px',
          height: '56px',
          borderRadius: '12px',
          border: `1px solid var(--color-border-light)`
        }}>
          {companyLogo ? null : <div className="d-flex flex-col m-auto">
            <h3 className="font-inter">
              {interviewTitle ? interviewTitle[0] : ''}
            </h3>
          </div>}
        </div>
        <div className="d-flex flex-col my-auto" style={{
          marginLeft: '16px'
        }}>
          <h4 className="job-title-header font-inter">
            {interviewTitle}
          </h4>
          <p className="job-title-description font-inter">
            {location}
          </p>
        </div>
      </div>
    )
  })

  return (
    <>
      {/* Full width header */}
      <Grid container sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100
      }}>
        {/* Header */}
        <Grid item xs={12}>
          <Box className="d-flex flex-col" sx={{
            alignItems: 'center',
            backgroundColor: 'var(--color-light-bg)',
          }}>
            <JobHeader style={{ margin: '16px auto' }}/>
          </Box>
        </Grid>
      </Grid>
      {/* Columned container */}
      <Container sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        backgroundColor: 'var(--color-background-light)',
        minHeight: '100vh',
        padding: '24px',
        marginTop: '92px'
      }}>
      <Grid container>
        {/* Left Column */}
        {overview && overview.length > 0 && <Grid item xs={12} md={6}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            mx: 3,
            my: 2,
            backgroundColor: 'white',
            borderRadius: '16px'
          }}>
            <div className="d-flex flex-col" style={{ borderBottom: '1px solid var(--color-border-light)', width: '100%' }}>
              
              <h4 className="font-inter">Overview</h4>
              {/* Tags */}
              <div className="d-flex flex-row" style={{ marginTop: 16, marginBottom: 16, gap: 16, flexWrap: 'wrap' }}>
                {tags.map((tag, index) => (
                  <div className="tag-main" key={index}>
                    {tag}
                  </div>
                ))}
              </div>
            </div>

            <ReactMarkdown components={{
              h1: ({node, ...props}) => <h1 style={{ fontSize: '20px', fontWeight: 600, marginBottom: '12px', color: 'white' }} {...props} />,
              h2: ({node, ...props}) => <h2 style={{ fontSize: '18px', fontWeight: 600, marginBottom: '10px', color: 'white' }} {...props} />,
              h3: ({node, ...props}) => <h3 className="job-text-description-header font-inter" {...props} />,
              h4: ({node, ...props}) => <h4 className="job-text-description-header font-inter" {...props} />,
              p: ({node, ...props}) => <p className="job-text-description font-inter" style={{ marginBottom: '24px' }} {...props} />,
              ul: ({node, ...props}) => <ul className="job-text-description font-inter" style={{ marginBottom: '24px' }} {...props} />,
            }}>
              {overview}
            </ReactMarkdown>
          </Box>
        </Grid>}

        {/* Right Column */}
        <Grid item xs={12} md={overview && overview.length > 0 ? 6 : 12} sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: overview && overview.length > 0 ? 'flex-start' : 'center'
        }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'white',
              border: '1px solid var(--color-border-light)',
              borderRadius: '12px',
              padding: '24px',
              marginLeft: overview && overview.length > 0 ? '24px' : '0',
              maxWidth: overview && overview.length > 0 ? 'none' : '600px',
              width: '100%'
            }}
          >
            <h4 className="font-inter" style={{ marginBottom: '24px' }}>Enter your details to apply and continue to the AI interview</h4>
            <form onSubmit={handleSubmit} noValidate>
              <Box
                sx={{
                  width: '100%',
                  gap: 2,
                }}
              >
                <FormField
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required={true}
                />
                <FormField
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required={true}
                />
                {additionalFields.includes('location') && (
                  <FormField
                    label="Location"
                    name="location"
                    value={additionalFormData.location}
                    onChange={handleAdditionalChange}
                  />
                )}
                {additionalFields.includes('linkedin') && (
                  <FormField
                    label="LinkedIn"
                    name="linkedin"
                    value={additionalFormData.linkedin}
                    onChange={handleAdditionalChange}
                  />
                )}
                {additionalFields.includes('google_scholar') && (
                  <FormField
                    label="Google Scholar"
                    name="google_scholar"
                    value={additionalFormData.google_scholar}
                    onChange={handleAdditionalChange}
                  />
                )}  
                {additionalFields.includes('github') && (
                  <FormField
                    label="GitHub"
                    name="github"
                    value={additionalFormData.github}
                    onChange={handleAdditionalChange}
                  />
                )}                
                {additionalFields.includes('resume') && (
                  <>
                    <h5 className="input-label d-flex" style={{ fontSize: '14px', fontWeight: 500, color: 'var(--color-gray)' }}>
                      Resume
                      <span style={{ color: 'var(--color-required)', marginLeft: '4px' }}>*</span>
                    </h5>
                    <TooltipCustom
                      title={
                        <>
                          <p className="m-auto">
                            Supports .pdf file format.
                          </p>
                        </>
                      }
                    >
                      <Button
                        className="btn-upload d-flex flex-col"
                        sx={{
                          alignItems: "flex-start",
                          padding: "12px",
                          backgroundColor: "var(--color-lightgray) !important",
                          boxShadow: "none",
                          border: "1px dashed lightgray !important",
                          marginBottom: "16px"
                        }}
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                      >
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileUpload}
                          accept=".pdf"
                          required={true}
                          onClick={(e) => {
                            // Remove the required attribute before click to prevent browser validation
                            e.target.required = false;
                            setTimeout(() => {
                              // Restore it after click
                              e.target.required = true;
                            }, 100);
                          }}
                        />
                        <div className="d-flex flex-row">
                          {!file ? (
                            <div className="btn-main no-hover" style={{ height: "18px", width: "fit-content" }}>Upload file</div>
                          ) : (
                            <>
                              <p style={{ fontSize: "12px", margin: "auto 0" }}>
                                {file.name}
                              </p>
                              <Clear
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  clearFile();
                                }}
                                className="color-danger my-auto"
                                sx={{ marginLeft: "12px", cursor: "pointer" }}
                              />
                            </>
                          )}
                        </div>
                      </Button>
                    </TooltipCustom>
                  </>
                )}
                {additionalFields.includes('compensation_expectations') && (
                  <FormField
                    label="Compensation Expectations" 
                    name="compensation_expectations"
                    description="Please provide a figure in USD if in the US and CAD if in Canada"
                    value={additionalFormData.compensation_expectations}
                    onChange={handleAdditionalChange}
                    required={true}
                    placeholder="50,000 USD"
                  />
                )}
                <div className="d-flex flex-row" style={{ gap: 16 }}>
                {additionalFields.includes('preferred_start_date') && (
                  <>
                    <div className="d-flex flex-col" style={{ flex: 1 }}>
                    <h5 className="input-label d-flex" style={{ fontSize: '14px', fontWeight: 500, color: 'var(--color-gray)' }}>
                      Preferred Start Date
                      <span style={{ color: 'var(--color-required)', marginLeft: '4px' }}>*</span>
                    </h5>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          clearable
                          value={additionalFormData.preferred_start_date ? dayjs(additionalFormData.preferred_start_date) : null}
                          slotProps={{
                              textField: {
                                  size: "small",
                                  placeholder: "Start date",
                                  required: true
                              },
                              field: {
                                  clearable: true,
                                  onClear: () => {
                                    setAdditionalFormData({
                                      ...additionalFormData,
                                      preferred_start_date: null
                                    });
                                  }
                              },
                              inputAdornment: {
                                  position: 'start',
                              }
                          }}
                          slots={{
                              openPickerIcon: () => <CalendarToday sx={{ fontSize: 18, color: 'black', marginLeft: '4px' }} />,
                              clearIcon: () => <Close sx={{ fontSize: 18, color: 'black', marginRight: '4px' }} />,
                          }}
                          sx={{
                              width: '100%',
                              marginBottom: '16px',
                              '& .MuiInputBase-root': {
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  borderRadius: '8px',
                                  paddingTop: '1.5px',
                                  paddingBottom: '1.5px',
                              },
                              '& .MuiOutlinedInput-root': {
                                  borderRadius: '8px',
                                  boxShadow: 'none',
                                  transition: 'box-shadow 0.2s ease-in-out',
                                  '&:hover': {
                                      boxShadow: 'none'
                                  },
                                  '& .MuiOutlinedInput-notchedOutline': {
                                      borderColor: 'lightgray',
                                  },
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                      borderColor: 'lightgray',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: 'lightgray',
                                      borderWidth: '1px',
                                  },
                              },
                              '& .MuiIconButton-root': {
                                  padding: '4px',
                                  '&:hover': {
                                      backgroundColor: 'transparent'
                                  }
                              }
                          }}
                          onChange={(date) => {
                              setAdditionalFormData({
                                ...additionalFormData,
                                preferred_start_date: date ? date.format('YYYY-MM-DD') : ''
                              });
                          }}
                          PopperProps={{
                              sx: {
                                  '& .MuiPaper-root': {
                                      boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                                      borderRadius: '8px',
                                  },
                                  '& .MuiPickersDay-root': {
                                      fontSize: '14px',
                                      '&.Mui-selected': {
                                          backgroundColor: 'var(--color-primary)',
                                          '&:hover': {
                                              backgroundColor: 'var(--color-primary)',
                                          }
                                      }
                                  },
                                  '& .MuiDayCalendar-weekDayLabel': {
                                      fontSize: '14px',
                                  },
                                  '& .MuiPickersCalendarHeader-label': {
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                  }
                              }
                          }}
                      />
                    </LocalizationProvider>
                    </div>
                  </>
                )}  
                {additionalFields.includes('need_for_sponsorship') && (
                  <>
                    <div className="d-flex flex-col" style={{ flex: 1 }}>
                    <FormSelect 
                        label="Need for Sponsorship"
                        noBorder
                        variant="outlined"
                        value={additionalFormData.need_for_sponsorship}
                        onChange={(e) => {
                          setAdditionalFormData({
                            ...additionalFormData,
                            need_for_sponsorship: e.target.value
                          });
                        }}
                        required={true}
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                    </FormSelect>
                    </div>
                  </>
                )}  
                </div>
                
                {additionalFields.includes('links') && (
                  <FormField
                    label="Links"
                    name="links"
                    value={additionalFormData.links}
                    onChange={handleAdditionalChange}
                    required={true}
                  />
                )}  

                <Divider sx={{ margin: '16px 0px' }} />

                <p style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  color: 'var(--color-gray)',
                  marginTop: '16px',
                  marginBottom: '16px',
                  fontWeight: '500'
                }}>
                  <InfoOutlined sx={{fontSize: 16, marginRight: 1, verticalAlign: 'text-bottom'}} /> Please provide <b>either</b> an email address or phone number
                </p>

                <FormField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                    <div className="d-flex flex-col">
                      <FormSelect
                        label="Country Code"
                        name="phoneCode"
                        value={formData.phoneCode || '+1'}
                        onChange={handleChange}
                      >
                        <MenuItem value="+1">🇺🇸 +1 (US/CA)</MenuItem>
                        <MenuItem value="+7">🇷🇺 +7 (RU/KZ)</MenuItem>
                        <MenuItem value="+20">🇪🇬 +20 (EG)</MenuItem>
                        <MenuItem value="+27">🇿🇦 +27 (ZA)</MenuItem>
                        <MenuItem value="+30">🇬🇷 +30 (GR)</MenuItem>
                        <MenuItem value="+31">🇳🇱 +31 (NL)</MenuItem>
                        <MenuItem value="+32">🇧🇪 +32 (BE)</MenuItem>
                        <MenuItem value="+33">🇫🇷 +33 (FR)</MenuItem>
                        <MenuItem value="+34">🇪🇸 +34 (ES)</MenuItem>
                        <MenuItem value="+36">🇭🇺 +36 (HU)</MenuItem>
                        <MenuItem value="+39">🇮🇹 +39 (IT)</MenuItem>
                        <MenuItem value="+40">🇷🇴 +40 (RO)</MenuItem>
                        <MenuItem value="+41">🇨🇭 +41 (CH)</MenuItem>
                        <MenuItem value="+43">🇦🇹 +43 (AT)</MenuItem>
                        <MenuItem value="+44">🇬🇧 +44 (UK)</MenuItem>
                        <MenuItem value="+45">🇩🇰 +45 (DK)</MenuItem>
                        <MenuItem value="+46">🇸🇪 +46 (SE)</MenuItem>
                        <MenuItem value="+47">🇳🇴 +47 (NO)</MenuItem>
                        <MenuItem value="+48">🇵🇱 +48 (PL)</MenuItem>
                        <MenuItem value="+49">🇩🇪 +49 (DE)</MenuItem>
                        <MenuItem value="+51">🇵🇪 +51 (PE)</MenuItem>
                        <MenuItem value="+52">🇲🇽 +52 (MX)</MenuItem>
                        <MenuItem value="+54">🇦🇷 +54 (AR)</MenuItem>
                        <MenuItem value="+55">🇧🇷 +55 (BR)</MenuItem>
                        <MenuItem value="+56">🇨🇱 +56 (CL)</MenuItem>
                        <MenuItem value="+57">🇨🇴 +57 (CO)</MenuItem>
                        <MenuItem value="+58">🇻🇪 +58 (VE)</MenuItem>
                        <MenuItem value="+60">🇲🇾 +60 (MY)</MenuItem>
                        <MenuItem value="+61">🇦🇺 +61 (AU)</MenuItem>
                        <MenuItem value="+62">🇮🇩 +62 (ID)</MenuItem>
                        <MenuItem value="+63">🇵🇭 +63 (PH)</MenuItem>
                        <MenuItem value="+64">🇳🇿 +64 (NZ)</MenuItem>
                        <MenuItem value="+65">🇸🇬 +65 (SG)</MenuItem>
                        <MenuItem value="+66">🇹🇭 +66 (TH)</MenuItem>
                        <MenuItem value="+81">🇯🇵 +81 (JP)</MenuItem>
                        <MenuItem value="+82">🇰🇷 +82 (KR)</MenuItem>
                        <MenuItem value="+84">🇻🇳 +84 (VN)</MenuItem>
                        <MenuItem value="+86">🇨🇳 +86 (CN)</MenuItem>
                        <MenuItem value="+90">🇹🇷 +90 (TR)</MenuItem>
                        <MenuItem value="+91">🇮🇳 +91 (IN)</MenuItem>
                        <MenuItem value="+92">🇵🇰 +92 (PK)</MenuItem>
                        <MenuItem value="+93">🇦🇫 +93 (AF)</MenuItem>
                        <MenuItem value="+94">🇱🇰 +94 (LK)</MenuItem>
                        <MenuItem value="+95">🇲🇲 +95 (MM)</MenuItem>
                        <MenuItem value="+98">🇮🇷 +98 (IR)</MenuItem>
                        <MenuItem value="+212">🇲🇦 +212 (MA)</MenuItem>
                        <MenuItem value="+213">🇩🇿 +213 (DZ)</MenuItem>
                        <MenuItem value="+216">🇹🇳 +216 (TN)</MenuItem>
                        <MenuItem value="+218">🇱🇾 +218 (LY)</MenuItem>
                        <MenuItem value="+220">🇬🇲 +220 (GM)</MenuItem>
                        <MenuItem value="+221">🇸🇳 +221 (SN)</MenuItem>
                        <MenuItem value="+234">🇳🇬 +234 (NG)</MenuItem>
                        <MenuItem value="+254">🇰🇪 +254 (KE)</MenuItem>
                        <MenuItem value="+255">🇹🇿 +255 (TZ)</MenuItem>
                        <MenuItem value="+256">🇺🇬 +256 (UG)</MenuItem>
                        <MenuItem value="+260">🇿🇲 +260 (ZM)</MenuItem>
                        <MenuItem value="+263">🇿🇼 +263 (ZW)</MenuItem>
                        <MenuItem value="+351">🇵🇹 +351 (PT)</MenuItem>
                        <MenuItem value="+352">🇱🇺 +352 (LU)</MenuItem>
                        <MenuItem value="+353">🇮🇪 +353 (IE)</MenuItem>
                        <MenuItem value="+354">🇮🇸 +354 (IS)</MenuItem>
                        <MenuItem value="+355">🇦🇱 +355 (AL)</MenuItem>
                        <MenuItem value="+356">🇲🇹 +356 (MT)</MenuItem>
                        <MenuItem value="+357">🇨🇾 +357 (CY)</MenuItem>
                        <MenuItem value="+358">🇫🇮 +358 (FI)</MenuItem>
                        <MenuItem value="+359">🇧🇬 +359 (BG)</MenuItem>
                        <MenuItem value="+370">🇱🇹 +370 (LT)</MenuItem>
                        <MenuItem value="+371">🇱🇻 +371 (LV)</MenuItem>
                        <MenuItem value="+372">🇪🇪 +372 (EE)</MenuItem>
                        <MenuItem value="+373">🇲🇩 +373 (MD)</MenuItem>
                        <MenuItem value="+374">🇦🇲 +374 (AM)</MenuItem>
                        <MenuItem value="+375">🇧🇾 +375 (BY)</MenuItem>
                        <MenuItem value="+380">🇺🇦 +380 (UA)</MenuItem>
                        <MenuItem value="+381">🇷🇸 +381 (RS)</MenuItem>
                        <MenuItem value="+385">🇭🇷 +385 (HR)</MenuItem>
                        <MenuItem value="+386">🇸🇮 +386 (SI)</MenuItem>
                        <MenuItem value="+420">🇨🇿 +420 (CZ)</MenuItem>
                        <MenuItem value="+421">🇸🇰 +421 (SK)</MenuItem>
                        <MenuItem value="+886">🇹🇼 +886 (TW)</MenuItem>
                        <MenuItem value="+961">🇱🇧 +961 (LB)</MenuItem>
                        <MenuItem value="+962">🇯🇴 +962 (JO)</MenuItem>
                        <MenuItem value="+963">🇸🇾 +963 (SY)</MenuItem>
                        <MenuItem value="+964">🇮🇶 +964 (IQ)</MenuItem>
                        <MenuItem value="+965">🇰🇼 +965 (KW)</MenuItem>
                        <MenuItem value="+966">🇸🇦 +966 (SA)</MenuItem>
                        <MenuItem value="+971">🇦🇪 +971 (AE)</MenuItem>
                        <MenuItem value="+972">🇮🇱 +972 (IL)</MenuItem>
                        <MenuItem value="+973">🇧🇭 +973 (BH)</MenuItem>
                        <MenuItem value="+974">🇶🇦 +974 (QA)</MenuItem>
                        <MenuItem value="+975">🇧🇹 +975 (BT)</MenuItem>
                        <MenuItem value="+976">🇲🇳 +976 (MN)</MenuItem>
                        <MenuItem value="+977">🇳🇵 +977 (NP)</MenuItem>
                        <MenuItem value="+992">🇹🇯 +992 (TJ)</MenuItem>
                        <MenuItem value="+993">🇹🇲 +993 (TM)</MenuItem>
                        <MenuItem value="+994">🇦🇿 +994 (AZ)</MenuItem>
                        <MenuItem value="+995">🇬🇪 +995 (GE)</MenuItem>
                        <MenuItem value="+996">🇰🇬 +996 (KG)</MenuItem>
                        <MenuItem value="+998">🇺🇿 +998 (UZ)</MenuItem>
                      </FormSelect>
                    </div>
                    <div className="d-flex flex-col">
                      <FormField
                        label="Phone Number"
                        name="phone"
                        type="tel"
                        value={formData.phone} 
                        onChange={handleChange}
                        helperText="Enter number without country code"
                        sx={{ 
                          flex: 1
                        }}
                      />
                    </div>
                  </Box>
                </Box>

                {error && (
                  <p style={{ 
                    color: 'var(--color-required)',
                    fontFamily: 'Inter',
                    fontWeight: '500',
                    fontSize: '14px',
                    marginTop: '16px'
                  }}>
                    {error}
                  </p>
                )}
              </Box>
              <button 
                type="submit"
                className="btn-main"
                style={{
                  width: '100%',
                  marginTop: '24px',
                  padding: '12px 20px',
                  fontSize: '14px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                disabled={submitDisabled}
              >
                {loading ? (
                  <>
                    <CircularProgress size={14} style={{ color: "white", marginRight: "8px" }}/>
                    Creating Session...
                  </>
                ) : "Start AI Interview"}
              </button>
            </form>
          </Box>
          <div className="d-flex flex-col text-center mx-auto" style={{ width: '100px' }}>
            <p style={{ fontSize: '12px', color: 'var(--color-text-main)', marginBottom: '6px', marginTop: '16px' }}>Powered by</p>
            <a href="https://wayfaster.com" target="_blank" rel="noopener noreferrer" className="clickable-grow">
              <img src={Logo} alt="powered-by-wayfaster" style={{ width: '100px', opacity: 0.5 }} />
            </a>
          </div>
        </Grid>
      </Grid>
      </Container>
    </>
  )
};

export default Landing; 