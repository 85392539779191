import axios from 'axios';
import React, { useState } from "react";
import WayfasterLogo from './wayfaster-logo.svg';
import { Container, Checkbox, TextField } from '@mui/material';

import { proxyEndpoint } from './envMapping.js'


const OptIn = () => {
    const [consentChecked, setConsentChecked] = useState(false);
    const [phone, setPhone] = useState('');
    const [consentSent, setConsentSent] = useState(false);

    const handleSubmit = (e) => {
        setConsentSent(true);
        return;
    }

    const handleChange = (e) => {
        setPhone(e.target.value);
    }

    return (
        <div style={{ margin: 0, background: 'white', color: 'black', width: '100%', height: '100vh', display: 'flex' }}>
            <Container className="d-flex font-inter flex-column text-left my-auto" style={{ height: '100%', padding: '48px 32px' }}>
              
              <div className="font-inter d-flex" style={{ margin: '0 auto 24px', fontSize: 18, color: 'var(--color-text-on-dark)' }}>
                <a href="https://wayfaster.com" rel="noopener noreferrer" target="_blank"><img src={WayfasterLogo} alt="Wayfaster" style={{ height: '32px' }} /></a>
              </div>

              <h2 className="font-circular">Opt-In to Receive SMS Messages</h2>
              <p style={{ marginTop: '16px' }}>We will send you SMS messages to notify you of virtual interviews you are invited to take, session notifications, and other informational communications during the interview process.</p>
              
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  required
                  name="phone" 
                  label="Candidate Phone Number"
                  placeholder="+1 5555555555"
                  value={phone}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontFamily: 'PPNeueMontreal',
                      fontSize: '14px'
                    },
                    '& .MuiInputBase-root': {
                      fontFamily: 'PPNeueMontreal',
                      fontSize: '14px'
                    }
                  }}
                />
              </form>

              <div className="d-flex flex-row" style={{ padding: '0px 8px' }}>
                <Checkbox
                className="my-auto"
                style={{
                  height: 'fit-content',
                }}
                checked={consentChecked}
                onClick={() => {
                  setConsentChecked(!consentChecked);
                }}
                />
                <p style={{color: 'var(--color-background-dark-secondary)', marginTop: '8px' }}>I agree to receive SMS notifications & alerts from Wayfaster. Message frequency varies. Message & data rates may apply. Text HELP to +18445436533 for assistance. You can reply STOP to unsubscribe at any time.
                  &nbsp;<a href="https://wayfaster.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                </p>
              </div>
              
              <button className="btn-main" onClick={() => { handleSubmit() }} disabled={!consentChecked || !phone}>Submit</button>
              
              {consentSent && (
                <p style={{ marginTop: '16px' }}>Thank you for opting in! You will receive SMS messages from Wayfaster.</p>
              )}

              </Container>
        </div>
    )
}

export default OptIn;