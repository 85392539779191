import React from 'react';

enum Role {
    Agent = 'agent',
    User = 'user'
}

interface TranscriptMessageProps {
    message: string;
    role: Role;
    speaker: string;
    isMobile: boolean;
    showSpeaker?: boolean;
    isLastInGroup?: boolean;
}

const TranscriptMessage = React.memo(({ 
    message, 
    role, 
    speaker, 
    isMobile, 
    showSpeaker = true,
    isLastInGroup = true 
}: TranscriptMessageProps) => {

    return (
        <div className="message-container" style={{ 
            marginBottom: isLastInGroup ? '24px' : '8px' 
        }}>
            {showSpeaker && (
                <h4 className={`font-inter ${role === Role.Agent ? 'text-left' : 'text-right'}`} style={{ marginBottom: '8px', color: 'white', fontSize: '14px', fontWeight: '400' }}>{speaker}</h4>
            )}
            <div className={`transcript-bubble d-flex ${role === Role.Agent ? 'interviewer' : 'candidate'}`}>
                {message}
            </div>
        </div>
    );
});

export default TranscriptMessage;