// applicable only to https://sessions.wayfaster.com
const getLocalStorageUserId = () => {
    // get from search params if exists
    const searchParams = new URLSearchParams(window.location.search);
    const directLocalUserId = searchParams.get('direct_local_user_id');
    if (directLocalUserId) {
        localStorage.setItem('user_uuid', directLocalUserId);
        return directLocalUserId;
    } else {
        return localStorage.getItem('user_uuid');
    }
  }
  
  export { getLocalStorageUserId };