// env mapping
var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '6319e72d-0dc8-4bbc-86cc-09d1701df65d' // prod
var env = String(process.env.REACT_APP_ENV)

// RETELL ENDPOINTS
var retellApiEndpoint = 'https://retell-main-proxy-69440b772c45.herokuapp.com'
var retellWsEndpoint = 'wss://retell-main-proxy-69440b772c45.herokuapp.com'

// Voice agent IDs
var defaultVoiceId = '11labs-Jason'
var defaultVoiceLanguage = 'English'
var defaultVoiceVersion = 1

if (env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
  retellApiEndpoint = 'https://staging-core-voice-api-2ac3e4de69f0.herokuapp.com'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
  retellApiEndpoint = 'http://localhost:8080'
  retellWsEndpoint = 'ws://localhost:8080'
}

var agentMappingEnglish = {
  '11labs-Jason': {'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Dorothy': {'name': 'Dorothy', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Dorothy.png'},
  'openai-Nova': {'name': 'Nova', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nova.png'},
  '11labs-Anthony': {'name': 'Anthony', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anthony.png'},
  '11labs-Lily': {'name': 'Lily', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lily.png'},
  '11labs-Marissa': {'name': 'Marissa', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Marissa.png'},
  '11labs-Brian': {'name': 'Brian', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/brian.png'},
  '11labs-Kate': {'name': 'Kate', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Kate.png'},
  'openai-Fable': {'name': 'Fable', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/fable.png'},
  '11labs-Paul': {'name': 'Paul', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/paul.png'},
  '11labs-Joe': {'name': 'Joe', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/joe.png'},
  '11labs-Adrian': {'name': 'Adrian', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/adrian.png'},
  '11labs-Zuri': {'name': 'Zuri', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Zuri.png'},
  '11labs-Chloe': {'name': 'Chloe', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Chloe.png'},
  '11labs-Max': {'name': 'Max', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/max.png'},
  '11labs-Julia': {'name': 'Julia', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Julia.png'},
  '11labs-Susan': {'name': 'Susan', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Susan.png'},
  '11labs-Kathrine': {'name': 'Kathrine', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/kathrine.png'},
  '11labs-Nina': {'name': 'Nina', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nina.png'},
  '11labs-Myra': {'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Paola': {'name': 'Paola', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Paola.png'},
  '11labs-Evie': {'name': 'Evie', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/evie.png'},
  '11labs-James': {'name': 'James', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/james.png'},
  '11labs-Ryan': {'name': 'Ryan', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/ryan.png'},
  '11labs-Mia': {'name': 'Mia', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/mia.png'}
}

var agentMappingChinese = {
  '11labs-Jason': {'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Evie': {'name': 'Evie', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/evie.png'},
}
var agentMappingFrench = {
  '11labs-Jason': {'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Gilfoy': {'name': 'Gilfoy', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/gilfoy.png'},
}
var agentMappingGerman = {
  '11labs-Jason': {'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Carola': {'name': 'Carola', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/carola.png'},
}
var agentMappingHindi = {
  '11labs-Jason': {'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Samad': {'name': 'Samad', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/samad.png'},
  '11labs-Monika': {'name': 'Monika', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/monika.png'},
}
var agentMappingSpanish = {
  '11labs-Jason': {'name': 'Jason', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png'},
  '11labs-Myra': {'name': 'Myra', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png'},
  '11labs-Santiago': {'name': 'Santiago', 'photo': 'https://retell-utils-public.s3.us-west-2.amazonaws.com/santiago.png'},
}

var languageAgentMapping = {
  'English': agentMappingEnglish,
  'Chinese': agentMappingChinese,
  'French': agentMappingFrench,
  'German': agentMappingGerman,
  'Hindi': agentMappingHindi,
  'Spanish': agentMappingSpanish,
}

const buildRetellEnvKey = (voice, language, env, version) => {
  voice = voice ? voice : defaultVoiceId
  language = language ? language : defaultVoiceLanguage
  version = version ? version : defaultVoiceVersion

  const voiceName = voice.split('-')[1].toUpperCase();
  const languageSuffix = language === 'English' ? '' : '_' + language;
  const envSuffix = env === 'staging' ? '_STAGING' : 
                   env === 'development' ? '' : 
                   '_PRODUCTION';
  const versionSuffix = version === 2 ? '_V2' : '';
  return `REACT_APP_RETELL_AGENT_ID_${voiceName}${languageSuffix}${envSuffix}${versionSuffix}`;
}

const getRetellAgentPhoto = (voice, language) => {
  voice = voice ? voice : defaultVoiceId
  language = language ? language : defaultVoiceLanguage
  return languageAgentMapping[language][voice].photo
}

export { proxyEndpoint, wsEndpoint, vapiEnvId, retellApiEndpoint, retellWsEndpoint, languageAgentMapping, buildRetellEnvKey, getRetellAgentPhoto }