import React, { useState, useEffect, useRef } from 'react';
import { Alert, Snackbar } from '@mui/material';

interface VideoFeedProps {
    videoStream: MediaStream | null;
    speaker: string;
    isMobile: boolean;
}

const VideoFeed = React.memo(({ videoStream, speaker, isMobile }: VideoFeedProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const checkInterval = useRef<NodeJS.Timeout | null>(null);
    const analyzerRef = useRef<AnalyserNode | null>(null);
    const animationFrameRef = useRef<number>();
    const speakingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [isVideoCovered, setIsVideoCovered] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
    
    // Advanced detection for covered/blocked webcams, including detection of thumbs and hands
    const checkIfVideoIsBlack = () => {
        if (!videoRef.current || !videoRef.current.videoWidth || !videoRef.current.videoHeight) {
            return false;
        }
        
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        
        if (!context || !canvas) return false;
        
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        
        // Draw the current video frame to the canvas
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        
        // Get the pixel data from the canvas
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const pixels = imageData.data;
        
        // Create much denser sampling grid for more thorough analysis
        const gridSize = 5; // Increase from 3 to 5 for more sample points
        const regions = [];
        
        // Create a dense grid of sampling points
        for (let i = 0; i <= gridSize; i++) {
            for (let j = 0; j <= gridSize; j++) {
                regions.push({
                    x: Math.floor(canvas.width * (i / gridSize)),
                    y: Math.floor(canvas.height * (j / gridSize))
                });
            }
        }
        
        // Add center region points for better coverage
        regions.push({ x: Math.floor(canvas.width * 0.5), y: Math.floor(canvas.height * 0.5) });
        
        // Statistics to track
        let brightPoints = 0;
        let totalBrightness = 0;
        let redDominantPoints = 0;
        let uniformColorPoints = 0;
        let totalHue = 0;
        let totalSaturation = 0;
        let hasSufficientContrast = false;
        let hasNormalColorDistribution = false;
        let maxBrightness = 0;
        let minBrightness = 255;
        let colorHistogram = {
            red: 0,
            green: 0,
            blue: 0
        };
        
        // RGB to HSV conversion helper for color analysis
        const rgbToHsv = (r: number, g: number, b: number) => {
            r /= 255;
            g /= 255;
            b /= 255;
        
            const max = Math.max(r, g, b);
            const min = Math.min(r, g, b);
            let h = 0, s = 0, v = max;
        
            const d = max - min;
            s = max === 0 ? 0 : d / max;
        
            if (max === min) {
                h = 0; // achromatic
            } else {
                switch (max) {
                    case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                    case g: h = (b - r) / d + 2; break;
                    case b: h = (r - g) / d + 4; break;
                }
                h /= 6;
            }
        
            return { h: h * 360, s: s * 100, v: v * 100 };
        };
        
        // Check for suspicious patterns like solid blocks of color (especially in skin/thumb tones)
        const colorVariance = {
            r: 0,
            g: 0, 
            b: 0
        };
        const rgbValues: number[][] = [];
        
        // First pass to gather pixel data
        for (const region of regions) {
            const index = (region.y * canvas.width + region.x) * 4;
            if (index >= pixels.length) continue;
            
            const r = pixels[index];
            const g = pixels[index + 1];
            const b = pixels[index + 2];
            
            // Store RGB values for variance calculation
            rgbValues.push([r, g, b]);
            
            // Track color dominance in histogram
            if (r > g + 30 && r > b + 30) colorHistogram.red++;
            else if (g > r + 30 && g > b + 30) colorHistogram.green++;
            else if (b > r + 30 && b > g + 30) colorHistogram.blue++;
            
            // Detect skin tones (simplified detection - reddish/orange tones)
            const hsv = rgbToHsv(r, g, b);
            totalHue += hsv.h;
            totalSaturation += hsv.s;
            
            // Detect reddish skin tones (common for thumbs covering camera)
            // Skin tone hue is typically between 0-50 (red to orange/yellow)
            if (hsv.h >= 0 && hsv.h <= 50 && r > g && r > b) {
                redDominantPoints++;
            }
            
            // Calculate brightness (simple average method)
            const brightness = (r + g + b) / 3;
            totalBrightness += brightness;
            
            // Track brightness stats
            if (brightness > maxBrightness) maxBrightness = brightness;
            if (brightness < minBrightness) minBrightness = brightness;
            
            // Count points that exceed minimum brightness
            if (brightness > 15) {
                brightPoints++;
            }
        }
        
        // Calculate color variance (low variance = likely covered with consistent color like a finger)
        if (rgbValues.length > 0) {
            const rValues = rgbValues.map(rgb => rgb[0]);
            const gValues = rgbValues.map(rgb => rgb[1]);
            const bValues = rgbValues.map(rgb => rgb[2]);
            
            // Calculate average
            const rAvg = rValues.reduce((sum, val) => sum + val, 0) / rValues.length;
            const gAvg = gValues.reduce((sum, val) => sum + val, 0) / gValues.length;
            const bAvg = bValues.reduce((sum, val) => sum + val, 0) / bValues.length;
            
            // Calculate variance
            colorVariance.r = rValues.reduce((sum, val) => sum + Math.pow(val - rAvg, 2), 0) / rValues.length;
            colorVariance.g = gValues.reduce((sum, val) => sum + Math.pow(val - gAvg, 2), 0) / gValues.length;
            colorVariance.b = bValues.reduce((sum, val) => sum + Math.pow(val - bAvg, 2), 0) / bValues.length;
        }
        
        // Analyze entire image through histogram
        const totalPixels = regions.length;
        const avgHue = totalHue / totalPixels;
        const avgSaturation = totalSaturation / totalPixels;
        
        // Calculate various metrics to detect covering
        const contrast = maxBrightness - minBrightness;
        hasSufficientContrast = contrast > 30; // Increased threshold
        
        // Calculate average brightness across all sampled points
        const averageBrightness = totalBrightness / regions.length;
        
        // Calculate average color variance - low variance means uniform color (like a thumb)
        const avgColorVariance = (colorVariance.r + colorVariance.g + colorVariance.b) / 3;
        const hasLowColorVariance = avgColorVariance < 500; // Threshold for detecting uniform color blocks
        
        // Detect if the camera is likely covered by a finger/thumb (reddish color dominance)
        const isLikelyCoveredByFinger = redDominantPoints > regions.length * 0.6 && hasLowColorVariance;
        
        // Check if colors are distributed normally (as expected in a real scene)
        const maxColorCount = Math.max(colorHistogram.red, colorHistogram.green, colorHistogram.blue);
        const totalColoredPoints = colorHistogram.red + colorHistogram.green + colorHistogram.blue;
        hasNormalColorDistribution = maxColorCount < totalColoredPoints * 0.7; // No single color should dominate too much
        
        // Detect suspicious patterns
        const hasSuspiciousPattern = isLikelyCoveredByFinger || (hasLowColorVariance && !hasNormalColorDistribution);
        
        // Evaluate multiple criteria to determine if camera is usable
        const hasAdequateImage = (brightPoints >= Math.ceil(regions.length * 0.3)) && // At least 30% of points should be bright enough
                                hasSufficientContrast && // Should have adequate contrast
                                !hasSuspiciousPattern && // Should not show suspicious patterns
                                (averageBrightness > 15); // Minimum average brightness
        
        return hasAdequateImage;
    };
    
    useEffect(() => {
        if (videoRef.current && videoStream) {
            videoRef.current.srcObject = videoStream;
            
            // Set up audio analysis if the stream has audio tracks
            if (videoStream.getAudioTracks().length > 0) {
                const audioContext = new AudioContext();
                const source = audioContext.createMediaStreamSource(videoStream);
                const analyzer = audioContext.createAnalyser();
                analyzer.fftSize = 512;
                analyzer.smoothingTimeConstant = 0.8;
                source.connect(analyzer);
                analyzerRef.current = analyzer;

                const checkAudioLevel = () => {
                    if (!analyzer) return;

                    const dataArray = new Uint8Array(analyzer.frequencyBinCount);
                    analyzer.getByteFrequencyData(dataArray);

                    // Calculate average audio level
                    const average = dataArray.reduce((sum, val) => sum + val, 0) / dataArray.length;
                    
                    // Set speaking state based on audio level threshold with debounce
                    if (average > 40) {
                        setIsSpeaking(true);
                        // Reset the timeout each time we detect speaking
                        if (speakingTimeoutRef.current) {
                            clearTimeout(speakingTimeoutRef.current);
                            speakingTimeoutRef.current = null;
                        }
                    } else if (average < 10) { // Only start the timeout if audio is very low
                        // Set a timeout to turn off speaking after 0.8 second of silence
                        if (!speakingTimeoutRef.current) {
                            speakingTimeoutRef.current = setTimeout(() => {
                                setIsSpeaking(false);
                                speakingTimeoutRef.current = null;
                            }, 500);
                        }
                    }

                    animationFrameRef.current = requestAnimationFrame(checkAudioLevel);
                };

                checkAudioLevel();
            }
        }
        
        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            if (speakingTimeoutRef.current) {
                clearTimeout(speakingTimeoutRef.current);
            }
        };
    }, [videoStream]);

    const handleCloseWarning = () => {
        setShowWarning(false);
    };

    if (!videoStream) return null;

    return (
        <div className={`d-flex my-auto candidate-video-feed ${isSpeaking ? 'speaking' : ''}`} style={{
            height: 'fit-content',
            borderRadius: '16px',
            overflowX: 'hidden', // make sure the video doesn't overflow the container
            objectFit: 'cover',
            position: 'relative',
            flex: 1,
            zIndex: 1000
        }}>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                className="video-feed-mirrored my-auto"
                style={{
                    width: '100%',
                    height: 'auto',
                    aspectRatio: isMobile ? '1 / 1' : '4 / 3',
                    border: isVideoCovered ? '3px solid #f44336' : isMobile ? 'none' : '1px solid var(--color-background-dark)',
                    backgroundColor: 'var(--color-video-bg)',
                    objectFit: 'cover',
                    position: 'relative',
                    zIndex: 1000
                }}
            >
            </video>
            <h4 style={{ color: 'white', position: 'absolute', bottom: 16, left: 16, zIndex: 1001 }}>{speaker}</h4>
            
            {/* Prominent warning message when camera is covered */}
            {isVideoCovered && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1002,
                    borderRadius: '16px',
                    padding: '20px',
                    textAlign: 'center'
                }}>
                    <Alert 
                        severity="error"
                        variant="filled" 
                        sx={{ 
                            width: '80%', 
                            fontSize: '18px',
                            marginBottom: '20px',
                            padding: '15px'
                        }}
                    >
                        ⚠️ WEBCAM REQUIRED ⚠️
                    </Alert>
                    <div style={{
                        color: 'white',
                        fontSize: '22px',
                        fontWeight: 'bold',
                        marginBottom: '15px'
                    }}>
                        Your camera appears to be covered or showing a black screen
                    </div>
                    <div style={{
                        color: 'white',
                        fontSize: '18px',
                        marginBottom: '20px'
                    }}>
                        We've detected that your webcam may be:
                        <ul style={{ 
                            textAlign: 'left', 
                            margin: '15px auto', 
                            width: 'fit-content', 
                            color: '#ff9999' 
                        }}>
                            <li>Covered by your thumb or finger</li>
                            <li>Obstructed by an object</li>
                            <li>Physically blocked or covered</li>
                            <li>Showing a solid color or black screen</li>
                        </ul>
                        Please uncover your camera immediately to continue with the interview.
                        <br/>
                        Make sure your webcam lens is completely clear and visible.
                    </div>
                    <Alert 
                        severity="warning"
                        sx={{ 
                            width: '80%', 
                            fontSize: '16px',
                        }}
                    >
                        Interview cannot proceed until a working webcam is detected
                    </Alert>
                </div>
            )}
            
            {/* Snackbar for less severe notification */}
            <Snackbar 
                open={showWarning && !isVideoCovered} 
                autoHideDuration={null} 
                onClose={handleCloseWarning}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseWarning} 
                    severity="error" 
                    sx={{ width: '100%', fontSize: '16px' }}
                >
                    Your camera appears to be covered or showing a black screen. Please uncover your camera to continue with the interview.
                </Alert>
            </Snackbar>
        </div>
    );
});

export default VideoFeed;