import React from "react";
import WayfasterLogo from './wayfaster-logo.svg';
import { Container  } from '@mui/material';


const Privacy = () => {
    return (
        <div style={{ margin: 0, background: 'white', color: 'black', width: '100%', height: '100vh', display: 'flex' }}>
            <Container className="d-flex font-inter flex-column text-left my-auto" style={{ height: '100%', padding: '48px 32px' }}>
              
              <div className="font-inter d-flex" style={{ margin: '0 auto 24px', fontSize: 18, color: 'var(--color-text-on-dark)' }}>
                <a href="https://wayfaster.com" rel="noopener noreferrer" target="_blank"><img src={WayfasterLogo} alt="Wayfaster" style={{ height: '32px' }} /></a>
              </div>
              <h2 className="font-circular" style={{ marginBottom: '8px' }}>Privacy Policy</h2>
              <code style={{ fontSize: 14 }}>Effective Date: January 1, 2025</code>

              <p style={{ marginTop: '16px' }}>We value your privacy and make every effort to respect your wishes and personal information. Please read this policy carefully to understand how we collect, use, and manage your phone numbers.</p>

              <h4 className="font-circular" style={{ marginTop: '24px' }}>COLLECTION OF PHONE NUMBERS</h4>

              <p style={{ marginTop: '8px' }}>We collect your phone numbers only when you voluntarily provide them to us, for
              example, during your job application process, inquiries, or when you sign up for to receive notifications.
              You can opt in to receive these SMS messages by providing your phone number during our onboarding process.</p>

              <h4 className="font-circular" style={{ marginTop: '24px' }}>USE OF PHONE NUMBERS FOR SMS</h4>

              <p style={{ marginTop: '8px' }}>Your phone numbers are primarily used to provide you with notifications for virtual interviews you are invited to take, session notifications,
                and other informational communications. SMS messaging charges may be applied by your carrier.</p>
              <p style={{ marginTop: '8px' }}>We will not use your phone number for any other purpose without your express consent. We
              will only share your phone number with our SMS provider, subject to their privacy policy.</p>

              <h4 className="font-circular" style={{ marginTop: '24px' }}>OPTING OUT OF MARKETING MESSAGES</h4>

              <p style={{ marginTop: '8px' }}>If at any time you wish to stop receiving messages from us, you can opt out by texting STOP. You can also opt out by e-mailing us at support@wayfaster.com.</p>
              <p style={{ marginTop: '8px' }}>Please note that opting out of text communications may affect your ability to use certain features of our service.</p>

              <h4 className="font-circular" style={{ marginTop: '24px' }}>PRIVACY OF PHONE NUMBERS</h4>

              <p style={{ marginTop: '8px' }}>Once you have opted out, we will not send you any more SMS messages, nor will we sell or transfer your phone number to another party.</p>

              <h4 style={{ marginTop: '24px' }}>CHANGES TO THIS POLICY</h4>

              <p style={{ marginTop: '8px' }}>We may periodically update this policy. We will notify you about significant changes in the way we treat your information by placing a prominent notice on our site.</p>
              <p style={{ marginTop: '8px' }}>We thank you for your understanding and cooperation. If you have any questions or concerns about this policy, please feel free to contact us via the form on our site.</p>
            </Container>
        </div>
    )
}

export default Privacy;